<template>
  <div class="lead">
    <Loader v-if="isLoading" />
    <v-card class="px-4">
      <template v-if="isSent">
        <v-alert v-if="isSuccess" type="success">Sent successfully </v-alert>
        <v-alert v-else type="error"> Something went wrong </v-alert>
      </template>
      <v-card-title> Send a pre meeting email to: </v-card-title>

      <v-card-text>
        <v-form v-model="validate" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <div class="text-field__wrap">
                <div class="text-field__icon"><v-icon> mdi-at </v-icon></div>
                <div class="text-field__input">
                  <v-text-field
                    v-model.trim="email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    outlined
                    required
                    @change="isSent = false"
                  ></v-text-field>
                </div>
              </div>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
            <v-spacer></v-spacer>
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="3"
              xsm="12"
              align-center
            >
              <v-btn @click="clickSendHandler" x-large block color="success">
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formValidationMixin from "@/shared/validationRules";
import Loader from "@/components/global/Loader";

export default {
  data: () => ({
    email: "",
    validate: false,
    isLoading: false,
    isSent: false,
    isSuccess: false,
  }),
  components: { Loader },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
  },
  mixins: [formValidationMixin],
  methods: {
    ...mapActions("preMeeting", ["sendEmail"]),
    async clickSendHandler() {
      this.isSent = false;

      if (this.$refs.form.validate()) {
        try {
          await this.sendEmail({
            smmID: this.getCurrentUser.id,
            email: this.email,
          });

          this.email = "";
          this.isSuccess = true;
        } catch (e) {
          this.isSuccess = false;
        } finally {
          this.isSent = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: #ff5252 !important;
  margin-left: 10px;
}
.text-field {
  &__wrap {
    display: flex;
    width: 100%;
    position: relative;
    &.hidden {
      display: none;
    }
  }
  &__input {
    width: 100%;
    ::v-deep {
      .v-text-field .v-label {
        left: 25px !important;
      }
      .v-text-field .v-label--active {
        left: 0 !important;
      }
      input {
        padding-left: 30px !important;
      }
    }
  }
  &__icon {
    left: 10px;
    top: 19px;
    position: absolute;
    font-size: 16px;
  }
}
.request-info {
  display: flex;
  flex-direction: column;
  .cell {
    &:first-child {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    &__title {
      width: 100%;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: #1f2325;
      margin-bottom: 4px;
    }
  }
}
.static-loader {
  position: static !important;
}
</style>
